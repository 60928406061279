import { z } from 'zod'

import { UnitListItemSchema } from './unit-item'

export const UnitItemsSchema = z.object({
  content: z.array(UnitListItemSchema),
  pageable: z.object({
    sort: z.object({
      empty: z.boolean(),
      sorted: z.boolean(),
      unsorted: z.boolean(),
    }),
    offset: z.number().int(),
    pageNumber: z.number().min(0).int(),
    pageSize: z.number().min(1).int(),
    paged: z.boolean(),
    unpaged: z.boolean(),
  }),
  totalElements: z.number().min(0).int(),
  totalPages: z.number().min(0).int(),
  last: z.boolean(),
  number: z.number().int(),
  sort: z.object({
    empty: z.boolean(),
    sorted: z.boolean(),
    unsorted: z.boolean(),
  }),
  size: z.number().int(),
  numberOfElements: z.number().min(0).int(),
  first: z.boolean(),
  empty: z.boolean(),
})

export type UnitItems = z.infer<typeof UnitItemsSchema>
