import z from 'zod'

import { LanguageCode } from '~/i18n'

export const ArticleTranslationSchema = z.object({
  id: z.number(),
  article_id: z.number(),
  title: z.string().nullable(),
  price: z.number(),
  currency_code: z.string(),
  description: z.string().nullable(),
  languageCode: z.nativeEnum(LanguageCode),
  by_default: z.boolean(),
  benefits: z.string().nullable(),
  benefit_summary: z.string().nullable(),
  designers: z.string().nullable(),
  good_to_knows: z.string().nullable(),
  materials: z.string().nullable(),
  care_instructions: z.string().nullable(),
  technicalCompliance: z.string().nullable(),
  technicalEnergyEfficiencyImage: z.string().nullable(),
  technicalEnergyLabelImage: z.string().nullable(),
})

export const ArticleSchema = z.object({
  id: z.number(),
  lang_code: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  price: z.number(),
  qty: z.number().optional(),
  article_id: z.string(),
  iows_images: z.array(z.string()),
  currency_code: z.string(),
  articleTranslations: z.array(ArticleTranslationSchema).nullable(),
})
export type ArticleTranslation = z.infer<typeof ArticleTranslationSchema>
export type Article = z.infer<typeof ArticleSchema>
