import { z } from 'zod'

import { UnitItemStateSchema } from '~/schema/unit-item'

export const TotalProductsByUnitSchema = z.array(
  z.object({
    count: z.number(),
    state: UnitItemStateSchema,
  }),
)
export type TotalProductsByUnit = z.infer<typeof TotalProductsByUnitSchema>
