import { z } from 'zod'

export const UserSchema = z.object({
  username: z.string(),
  countryCode: z.string(),
  accessToken: z.string().nullable(),
  country: z.string().optional(),
  unit: z.string().nullable(),
  role: z.string(),
  displayName: z.string().nullable(),
  lastAccess: z.string(),
})

export type User = z.infer<typeof UserSchema>
