import { z } from 'zod'

export const LanguageSchema = z.object({
  id: z.number(),
  country_code: z.string(),
  lang_code: z.string(),
  name: z.string(),
  countryName: z.string(),
  by_default: z.boolean(),
})
export type Language = z.infer<typeof LanguageSchema>
export const LanguagesSchema = z.array(LanguageSchema)
export type Languages = z.infer<typeof LanguagesSchema>
