import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { api } from '~/clients/api'
import { type User, UserSchema } from '~/schema/user'

export const sessionStorageAuthKey = 'authorization'

export function getAccessToken() {
  const token = window.sessionStorage.getItem(sessionStorageAuthKey)
  if (!token) throw new Error('access token not found')
  return token
}

async function fetchUserData() {
  const response = await api.authentication.refresh()

  const user = response.data

  try {
    return UserSchema.parse(user)
  } catch (error) {
    console.error(error)
    return user as User
  }
}

export function useUser() {
  const router = useRouter()
  const query = useQuery({
    queryKey: ['user'],
    queryFn: () => fetchUserData(),
    retry: false,
    // 5 minutes
    staleTime: 1000 * 60 * 5,
  })
  useEffect(() => {
    if (query.isError) {
      const err = query.error
      if (err instanceof AxiosError) {
        if (err.response?.status && err.response.status >= 400) {
          window.sessionStorage.removeItem('ssoCode')
          window.sessionStorage.removeItem('authorization')
          router.push('/')
        }
      }
    }
  }, [query.isError, query.error, router])

  return query
}
