import { z } from 'zod'

export const ActivitySchema = z.object({
  id: z.number(),
  user: z.string(),
  unit: z.string(),
  countryCode: z.string(),
  item: z.number(),
  action: z.string(),
  timestamp: z.string(),
  tz: z.string(),
})
export type Activity = z.infer<typeof ActivitySchema>
