import z from 'zod'

export const UnitSchema = z.object({
  id: z.number(),
  name: z.string(),
  country_code: z.string(),
  store_id: z.string(),
  email: z.string().nullable().optional(),
  timezone: z.string(),
  open_hour: z.number(),
  close_hour: z.number(),
  reserved_hours: z.number(),
  active: z.boolean(),
})
export type Unit = z.infer<typeof UnitSchema>

export const UnitsSchema = z.array(UnitSchema)
export type Units = z.infer<typeof UnitsSchema>
