// have to import the json and then make it a const to have type safety
import defaultTranslation from '../public/locales/en/translation.json'
export const defaultNS = 'translation' as const
export const defaultResource = {
  ...{ [defaultNS]: defaultTranslation },
} as const

declare module 'i18next' {
  interface CustomTypeOptions {
    resources: typeof defaultResource
  }
}

export enum LanguageCode {
  cs = 'cs',
  da = 'da',
  de = 'de',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hr = 'hr',
  hu = 'hu',
  it = 'it',
  ko = 'ko',
  nl = 'nl',
  no = 'no',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  sr = 'sr',
  sk = 'sk',
  sl = 'sl',
  sv = 'sv',
  zh = 'zh',
}
